<div class="main-content d-flex flex-column">

    <app-ed-header></app-ed-header>

    <app-ed-sidebar></app-ed-sidebar>

    <router-outlet></router-outlet>
    
    <div class="flex-grow-1"></div>

    <app-ed-footer></app-ed-footer>

</div>