<!-- Default -->
<div
    class="jobs-area position-relative z-1 pb-100"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3'}"
>
    <div class="container">
        <div class="section-title text-start">
            <h2 class="mb-0">
                Popular jobs right now
            </h2>
            <select class="form-select fw-medium">
                <option selected>Full Time</option>
                <option value="1">Part Time</option>
                <option value="2">Hourly</option>
                <option value="3">Monthly</option>
            </select>
        </div>
        <div class="row">
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company8.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Mechanical Engineer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Topoint in London
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$1K - $3K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Oct 30, 2023
                            <span>(20 mins ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company9.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior Support Engineer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Zayper in Barcelona
                                </span>
                                <span class="job-type d-block">
                                    Part Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$10 - $50</span>
                            /hourly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 10, 2023
                            <span>(1 day ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company10.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior C# / .NET Developer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Doca in São Paulo
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$6K - $7K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 11, 2023
                            <span>(2 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company11.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior Project Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Techstar in Chicago
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$2K - $3K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 12, 2023
                            <span>(3 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company12.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Assistant Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Mund in Seoul
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$11K - $23K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 13, 2023
                            <span>(4 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company13.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Junior Banker
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Finix in Hong Kong
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$50 - $100</span>
                            /hourly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 14, 2023
                            <span>(5 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company14.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Founder Associate
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Aoriv in Toronto
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$10K - $33K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 15, 2023
                            <span>(6 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company15.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Marketing Executive
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Dking in Beijing
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$3K - $5K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 16, 2023
                            <span>(7 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company16.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Digital Marketing Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Oxygen in New York
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$2K - $2.5K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 17, 2023
                            <span>(8 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-box">
                    <div class="d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company17.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Technical SEO Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Affort in Tokyo
                                </span>
                                <span class="job-type d-block">
                                    Full Time
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn">
                            <a routerLink="/job-details" class="default-btn">
                                Apply
                            </a>
                        </div>
                    </div>
                    <div class="info d-sm-flex d-lg-block d-xl-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$15 - $25</span>
                            /hourly
                        </span>
                        <span class="deadline d-block">
                            <span class="fw-semibold">Deadline:</span>
                            Nov 18, 2023
                            <span>(9 days ago)</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-jobs-btn text-center">
            <p>We select the opportunities we like <a routerLink="/jobs-grid">Discover all opportunities</a></p>
        </div>
    </div>
    <img src="assets/images/shapes/shape1.png" class="shape" alt="shape-image">
</div>

<!-- Only for Home Demo - 2 -->
<div
    class="jobs-area bg-c29a94 ptb-100"
    *ngIf="router.url === '/index-2'"
>
    <div class="container">
        <div class="section-title text-start">
            <h2 class="mb-0">
                Popular jobs right now
            </h2>
            <a routerLink="/jobs-grid" class="link-btn d-inline-block fw-medium">
                Explore all jobs <i class="ri-arrow-right-up-line"></i>
            </a>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company8.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Mechanical Engineer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Topoint in London
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Oct 30, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $1K - $3K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            20 mins ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company9.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior Support Engineer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Zayper in Barcelona
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 10, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $10 - $50
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            1 day ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company10.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior C# / .NET Developer
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Doca in São Paulo
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 11, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $6K - $7K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            2 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company11.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Senior Project Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Techstar in Chicago
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 12, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $2K - $3K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            3 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company12.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Assistant Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Mund in Seoul
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 13, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $10K - $33K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            4 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company13.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Junior Banker
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Finix in Hong Kong
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 14, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $3K - $5K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            5 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company14.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Founder Associate
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Aoriv in Toronto
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 15, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $2K - $2.5K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            6 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company15.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Marketing Executive
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Dking in Beijing
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 16, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $15 - $25
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            7 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company16.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Digital Marketing Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Oxygen in New York
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 17, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $1K - $3K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            8 days ago
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-6 col-md-12" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-job-item position-relative">
                    <div class="d-sm-flex d-lg-block d-lg-flex align-items-center justify-content-between">
                        <div class="d-sm-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company17.png" alt="company-image">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    <a routerLink="/job-details">
                                        Technical SEO Manager
                                    </a>
                                </h3>
                                <span class="location d-block">
                                    Affort in Tokyo
                                </span>
                                <span class="deadline d-block">
                                    <span class="fw-medium">Deadline:</span>
                                    Nov 18, 2023
                                </span>
                            </div>
                        </div>
                        <div class="apply-btn text-center">
                            <a routerLink="/job-details" class="default-btn two">
                                Apply
                            </a>
                            <span class="d-block">
                                $3K - $5K
                            </span>
                        </div>
                    </div>
                    <span class="time">
                        <span class="d-block">
                            9 days ago
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 3 -->
<div
    class="jobs-area ptb-100"
    *ngIf="router.url === '/index-3'"
>
    <div class="container">
        <div class="section-title text-start">
            <h2 class="mb-0">
                Popular jobs right now
            </h2>
            <a routerLink="/jobs-grid" class="link-btn d-inline-block fw-medium">
                Explore all jobs <i class="ri-arrow-right-up-line"></i>
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company8.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Mechanical Engineer
                            </a>
                        </h3>
                        <span class="location d-block">
                            Topoint in London
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Oct 30, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$1K - $3K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company9.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Senior Support Engineer
                            </a>
                        </h3>
                        <span class="location d-block">
                            Zayper in Barcelona
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Part Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 10, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$10 - $50</span>
                            /hourly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag urgent">Urgent</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company10.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Senior C# / .NET Developer
                            </a>
                        </h3>
                        <span class="location d-block">
                            Doca in São Paulo
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 11, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$6K - $7K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company11.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Senior Project Manager
                            </a>
                        </h3>
                        <span class="location d-block">
                            Techstar in Chicago
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 12, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$2K - $3K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag urgent">Urgent</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company12.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Assistant Manager
                            </a>
                        </h3>
                        <span class="location d-block">
                            Mund in Seoul
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 13, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$11K - $23K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company13.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Junior Banker
                            </a>
                        </h3>
                        <span class="location d-block">
                            Finix in Hong Kong
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 14, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$50 - $100</span>
                            /hourly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag urgent">Urgent</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company14.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Founder Associate
                            </a>
                        </h3>
                        <span class="location d-block">
                            Aoriv in Toronto
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 15, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$10K - $33K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company15.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Marketing Executive
                            </a>
                        </h3>
                        <span class="location d-block">
                            Dking in Beijing
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 16, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$3K - $5K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="job-item text-center overflow-hidden position-relative">
                    <div class="content">
                        <div class="company-logo">
                            <img src="assets/images/companies/company16.png" alt="company-image">
                        </div>
                        <h3 class="fw-semibold">
                            <a routerLink="/job-details">
                                Digital Marketing Manager
                            </a>
                        </h3>
                        <span class="location d-block">
                            Oxygen in New York
                        </span>
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="job-type d-block">
                                Full Time
                            </span>
                            <span class="deadline d-block">
                                <span class="fw-medium">Deadline:</span>
                                Nov 17, 2023
                            </span>
                        </div>
                    </div>
                    <div class="info d-flex align-items-center justify-content-between">
                        <span class="salary d-block">
                            <span class="fw-semibold">Salary:</span>
                            <span>$2K - $2.5K</span>
                            /monthly
                        </span>
                        <span class="deadline d-block">
                            <a routerLink="/job-details" class="link-btn d-inline-block position-relative">
                                Apply Now <i class="ri-arrow-right-line"></i>
                            </a>
                        </span>
                    </div>
                    <button type="button" class="fav">
                        <i class="ri-heart-line"></i>
                    </button>
                    <span class="tag">Featured</span>
                </div>
            </div>
        </div>
        <div class="more-jobs-btn text-center">
            <p>We select the opportunities we like <a routerLink="/jobs-grid">Discover all opportunities</a></p>
        </div>
    </div>
</div>