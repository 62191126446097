<div class="navbar-area">
    <div class="container-fluid">
        <nav
            class="navbar navbar-expand-lg"
            [class.active]="classApplied"
        >
            <a class="navbar-brand" routerLink="/candidates-dashboard">
                <img src="assets/images/logo.png" alt="logo">
            </a>
            <button
                class="navbar-toggler"
                type="button"
                (click)="toggleClass()"
            >
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            Home
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Home Demo - 1
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Home Demo - 2
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/index-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Home Demo - 3
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            Jobs
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/jobs-grid" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Jobs Grid
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/jobs-listing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Jobs Listing
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/job-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Job Details
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/categories" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Categories
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            Employers
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/employers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Employers
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/employer-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Employer Details
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/dashboard" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Employers Dashboard
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            Candidates
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/candidates" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Candidates
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/candidate-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Candidate Details
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/candidates-dashboard" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Candidates Dashboard
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="others-option d-md-flex align-items-center">
                    <div class="option-item">
                        <form class="search-box position-relative">
                            <input type="text" class="input-search" placeholder="Search....">
                            <button type="submit">
                                <i class="ri-search-line"></i>
                            </button>
                        </form>
                    </div>
                    <div class="option-item d-inline-block">
                        <a routerLink="/dashboard/message" class="message-icon d-inline-block position-relative">
                            <i class="ri-message-2-line"></i>
                            <span>
                                2
                            </span>
                        </a>
                    </div>
                    <div class="option-item d-inline-block">
                        <a routerLink="/dashboard/resumes" class="notification-icon d-inline-block position-relative">
                            <i class="ri-notification-line"></i>
                            <span>
                                1
                            </span>
                        </a>
                    </div>
                    <div class="option-item">
                        <div class="dropdown">
                            <button
                                class="dropdown-toggle"
                                type="button"
                                (click)="toggleClass2()"
                            >
                                <div class="image">
                                    <img src="assets/images/users/user1.png" alt="company-image">
                                </div>
                                My Account
                                <i class="ri-arrow-down-s-line"></i>
                            </button>
                            <ul
                                class="dropdown-menu"
                                [class.active]="classApplied2"
                            >
                                <li>
                                    <a routerLink="/candidates-dashboard" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-dashboard-line"></i>
                                        Dashboard
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/candidates-dashboard/my-profile" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-user-line"></i>
                                        My Profile
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/candidates-dashboard/resume" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-send-plane-2-line"></i>
                                        Resume
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/candidates-dashboard/bookmarks" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-briefcase-line"></i>
                                        Bookmarks
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/candidates-dashboard/applied-jobs" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-file-list-3-line"></i>
                                        Applied Jobs
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/" class="d-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        <i class="ri-logout-box-line"></i>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>