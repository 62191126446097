/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminUsers } from '../models/admin-users';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminUsersList
   */
  static readonly GetadminUsersListPath = '/admin/users';

  /**
   * Get list of users.
   *
   * Returns list of users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminUsersList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminUsers>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUsersService.GetadminUsersListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminUsers>;
        }>;
      })
    );
  }

  /**
   * Get list of users.
   *
   * Returns list of users
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminUsersList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminUsers>;
}> {

    return this.getadminUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminUsers>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminUsers>;
})
    );
  }

  /**
   * Path part for operation storeadminUsers
   */
  static readonly StoreadminUsersPath = '/admin/users';

  /**
   * Store new Users.
   *
   * Returns Users data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminUsers$Response(params: {
    body: AdminUsers
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUsersService.StoreadminUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminUsers;
        }>;
      })
    );
  }

  /**
   * Store new Users.
   *
   * Returns Users data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminUsers(params: {
    body: AdminUsers
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}> {

    return this.storeadminUsers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
})
    );
  }

  /**
   * Path part for operation getadminUsersId
   */
  static readonly GetadminUsersIdPath = '/admin/users/{id}';

  /**
   * Get  Users by id.
   *
   * Returns Users data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminUsersId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminUsersId$Response(params: {

    /**
     * id of Users
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUsersService.GetadminUsersIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminUsers;
        }>;
      })
    );
  }

  /**
   * Get  Users by id.
   *
   * Returns Users data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminUsersId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminUsersId(params: {

    /**
     * id of Users
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}> {

    return this.getadminUsersId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
})
    );
  }

  /**
   * Path part for operation putadminUsers
   */
  static readonly PutadminUsersPath = '/admin/users/{id}';

  /**
   * Update Users.
   *
   * Returns Users data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminUsers$Response(params: {

    /**
     * id of Users
     */
    id: string;
    body: AdminUsers
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUsersService.PutadminUsersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminUsers;
        }>;
      })
    );
  }

  /**
   * Update Users.
   *
   * Returns Users data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminUsers(params: {

    /**
     * id of Users
     */
    id: string;
    body: AdminUsers
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}> {

    return this.putadminUsers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminUsers;
})
    );
  }

  /**
   * Path part for operation deleteadminUsers
   */
  static readonly DeleteadminUsersPath = '/admin/users/{id}';

  /**
   * Store new Users.
   *
   * Returns Users data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminUsers$Response(params: {

    /**
     * id of Users
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminUsersService.DeleteadminUsersPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Users.
   *
   * Returns Users data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminUsers(params: {

    /**
     * id of Users
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminUsers$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
