<!-- Default Style -->
<div
    class="get-hired-by-top-companies-area pt-100 pb-75 bg-fafafa"
    [ngClass]="{'d-none': router.url === '/index-3'}"
>
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Get hired by top companies
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company18.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Royal Styles.Co
                                </h3>
                                <span class="d-block">
                                    IT & Networking
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            New York
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company19.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Statistic Ltd
                                </h3>
                                <span class="d-block">
                                    Commerce & Industry
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Watertown
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company20.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Royalprinting
                                </h3>
                                <span class="d-block">
                                    Digital & eCommerce
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Fort Lee
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company21.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Fivestar Ltd
                                </h3>
                                <span class="d-block">
                                    Fashion & Lifestyle
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Hartford
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company22.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Airline Co
                                </h3>
                                <span class="d-block">
                                    Project Management
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Manhattan
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company23.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    North Star
                                </h3>
                                <span class="d-block">
                                    Human Resources
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Naugatuck
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company24.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Altra Store Ltd
                                </h3>
                                <span class="d-block">
                                    Food & Drink
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Edison
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company25.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    Starone Ltd
                                </h3>
                                <span class="d-block">
                                    Writing & Translation
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            New Haven
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="company-box position-relative">
                    <div class="d-xxl-flex align-items-center justify-content-between">
                        <div class="d-xxl-flex align-items-center">
                            <div class="company-logo">
                                <img src="assets/images/companies/company26.png" alt="company-logo">
                            </div>
                            <div class="content">
                                <h3 class="fw-semibold">
                                    The TCbrand
                                </h3>
                                <span class="d-block">
                                    Programming & Tech
                                </span>
                            </div>
                        </div>
                        <div class="location">
                            Queens
                        </div>
                    </div>
                    <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 3 -->
<div
    class="get-hired-by-top-companies-area pt-100 pb-75"
    *ngIf="router.url === '/index-3'"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="company-content" data-aos="fade-up" data-aos-duration="500">
                    <h2>
                        Get hired by top companies
                    </h2>
                    <p>
                        Your career deserves nothing but the best. Join us at Jove and explore job opportunities from top employers.
                    </p>
                    <ul class="ps-0 mb-0 list-unstyled">
                        <li class="d-flex align-items-center fw-semibold">
                            <div class="icon">
                                <i class="ri-check-line"></i>
                            </div>
                            Explore
                        </li>
                        <li class="d-flex align-items-center fw-semibold">
                            <div class="icon">
                                <i class="ri-check-line"></i>
                            </div>
                            Apply
                        </li>
                        <li class="d-flex align-items-center fw-semibold">
                            <div class="icon">
                                <i class="ri-check-line"></i>
                            </div>
                            Connect
                        </li>
                        <li class="d-flex align-items-center fw-semibold">
                            <div class="icon">
                                <i class="ri-check-line"></i>
                            </div>
                            Elevate
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company18.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            Royal Styles.Co
                                        </h3>
                                        <span class="d-block">
                                            IT & Networking
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    New York
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company19.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            Statistic Ltd
                                        </h3>
                                        <span class="d-block">
                                            Commerce & Industry
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    Watertown
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company20.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            Royalprinting
                                        </h3>
                                        <span class="d-block">
                                            Digital & eCommerce
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    Fort Lee
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company21.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            Fivestar Ltd
                                        </h3>
                                        <span class="d-block">
                                            Fashion & Lifestyle
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    Hartford
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company22.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            Airline Co
                                        </h3>
                                        <span class="d-block">
                                            Project Management
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    Manhattan
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                    <div class="col-sm-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                        <div class="company-box position-relative">
                            <div class="d-xxl-flex align-items-center justify-content-between">
                                <div class="d-xxl-flex align-items-center">
                                    <div class="company-logo">
                                        <img src="assets/images/companies/company23.png" alt="company-logo">
                                    </div>
                                    <div class="content">
                                        <h3 class="fw-semibold">
                                            North Star
                                        </h3>
                                        <span class="d-block">
                                            Human Resources
                                        </span>
                                    </div>
                                </div>
                                <div class="location">
                                    Naugatuck
                                </div>
                            </div>
                            <a routerLink="/employer-details" class="link-btn position-absolute z-1 start-0 end-0 top-0 bottom-0"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>