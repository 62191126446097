<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Privacy Policy
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Privacy Policy
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Privacy Policy -->
<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy-content">
            <p>Last updated on November 3, 2023.</p>
            <p>
                Hi there, we’re <strong>Jove</strong>, and welcome to our privacy policy which also applies to our customers.
                <br>
                This policy sets out how we handle your personal information if you’re an <strong>Jove</strong> user or visitor to our site.
            </p>
            <p>
                When we say ‘we’, ‘us’, or <strong>‘Jove’</strong>, it’s because that’s who we are and we own and run the site.
                <br>
                If we say ‘policy’ we’re talking about this privacy policy. If we say ‘user terms’ we’re talking about the rules for using the site. The rules vary by product; each product makes them separately available and seeks consent to them separately to this policy.
            </p>
            <h4>The type of personal information we collect</h4>
            <p>
                We collect certain personal information about visitors and users of our site.
                <br>
                The most common types of information we collect include things like: user-names, member names, email addresses, other contact details, support queries, and item comments (if applicable).
            </p>
            <h4>How we collect personal information</h4>
            <p>We collect personal information directly when you provide it to us, automatically as you navigate through the site.</p>
            <p>We collect your personal information when you provide it to us when you complete membership registration and buy items or services on our site, subscribe to a newsletter, email list, submit feedback or send us a communication.</p>
            <h4>How we use personal information</h4>
            <p>This data is intended to identify you as our <a routerLink="/">customer/subscriber</a> and deliver products/information and other marketing elements. Visitors aren’t allowed to purchase any product or subscribe to the newsletter or make a comment anonymously. So, this information is the only means we can identify you and allow you to make these actions on the site.</p>
            <h4>When we disclose your personal information</h4>
            <p>We won’t disclose your data to third parties in a way as to reveal any of your personal information like email, name, etc. (subject to laws and regulations). Only our <i>authorities</i> have access to your data and no third-party access to your data from us.</p>
            <h4>How we keep your personal information secure</h4>
            <p>We store personal information on secure servers managed by us, and our service providers and files are kept in a secure location. Personal <a href="#" target="_blank">information</a> that we store is protected by security and access controls, including username and password authentication, two-factor authentication, and data encryption where appropriate.</p>
            <h4>How you can access your personal information</h4>
            <p>You can access some of the personal information we collect by logging in to your account. You also have the right to request access to other personal information we hold about you and to request corrections of any errors in that data. Contact our support using the contact details to make an access or correction request.</p>
            <h4>Marketing Choices regarding your personal information</h4>
            <p>Where we have your consent to do so (e.g., if you have subscribed to one of our email lists or have indicated that you are interested in receiving offers or information from us), we send you marketing communications by email about products and services that we feel may be of interest to you. You can ‘opt-out’ of such communications if you prefer not to receive them in the future by using the “unsubscribe” facility provided in the communication.</p>
            <p>You also have choices about <u>cookies</u>, as described below. By modifying your browser preferences, you can accept all cookies, be notified when a cookie is set, or reject all cookies. If you reject cookies, some parts of our site may not work properly in your case.</p>
            <h4>Cookies (not the type you eat!) and web analytics</h4>
            <p>We use cookies to identify and track visitors, their actions on the site, and their website access preferences. These cookies contain visitors’ IP and time of visits. <strong>Jove</strong> visitors who do not wish to have cookies placed on their browsers should set their browsers to refuse cookies before using the <strong>Jove</strong> site.</p>
            <h4>How long we keep your personal information</h4>
            <p>We retain your personal information for as long as is necessary to provide the services to you and others and to comply with our legal obligations. If you no longer want us to use your personal information or to provide you with the <strong>Jove</strong> services, you can request that we erase your personal information and close your account.</p>
            <h4>When we need to update this policy</h4>
            <p>We will need to change this policy from time to time to ensure it stays up to date with the latest legal requirements and any changes to our privacy management practices. When changing the policy, we will notify you about such changes; where required, a copy of the latest version of this policy will always be available on this page.</p>
            <h4>How you can contact us</h4>
            <p>If you have any questions about our privacy practices or how we have been managing your personal information, please get in touch with our support at <a href="hello@jove.com">hello&#64;jove.com</a>.</p>
            <p>Both personal information and personal data have the same meaning in the context of this Privacy Policy.</p>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>