import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-message',
  templateUrl: './cd-message.component.html',
  styleUrls: ['./cd-message.component.scss']
})
export class CdMessageComponent {

}
