/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterStatusJumlah } from '../models/admin-master-status-jumlah';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterStatusJumlahService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getmasterStatusJumlahsList
   */
  static readonly GetmasterStatusJumlahsListPath = '/admin/masterStatusJumlahs';

  /**
   * Get list of masterStatusJumlahs.
   *
   * Returns list of masterStatusJumlahs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getmasterStatusJumlahsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterStatusJumlahsList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusJumlah>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusJumlahService.GetmasterStatusJumlahsListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterStatusJumlah>;
        }>;
      })
    );
  }

  /**
   * Get list of masterStatusJumlahs.
   *
   * Returns list of masterStatusJumlahs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getmasterStatusJumlahsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterStatusJumlahsList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusJumlah>;
}> {

    return this.getmasterStatusJumlahsList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusJumlah>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusJumlah>;
})
    );
  }

  /**
   * Path part for operation storeMasterStatusJumlah
   */
  static readonly StoreMasterStatusJumlahPath = '/admin/masterStatusJumlahs';

  /**
   * Store new Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeMasterStatusJumlah()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterStatusJumlah$Response(params: {
    body: AdminMasterStatusJumlah
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusJumlahService.StoreMasterStatusJumlahPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusJumlah;
        }>;
      })
    );
  }

  /**
   * Store new Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeMasterStatusJumlah$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterStatusJumlah(params: {
    body: AdminMasterStatusJumlah
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}> {

    return this.storeMasterStatusJumlah$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
})
    );
  }

  /**
   * Path part for operation getMasterStatusJumlahId
   */
  static readonly GetMasterStatusJumlahIdPath = '/admin/masterStatusJumlahs/{id}';

  /**
   * Get  Master_status_jumlah by id.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterStatusJumlahId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterStatusJumlahId$Response(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusJumlahService.GetMasterStatusJumlahIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusJumlah;
        }>;
      })
    );
  }

  /**
   * Get  Master_status_jumlah by id.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMasterStatusJumlahId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterStatusJumlahId(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}> {

    return this.getMasterStatusJumlahId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
})
    );
  }

  /**
   * Path part for operation putMasterStatusJumlah
   */
  static readonly PutMasterStatusJumlahPath = '/admin/masterStatusJumlahs/{id}';

  /**
   * Update Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMasterStatusJumlah()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterStatusJumlah$Response(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
    body: AdminMasterStatusJumlah
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusJumlahService.PutMasterStatusJumlahPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusJumlah;
        }>;
      })
    );
  }

  /**
   * Update Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMasterStatusJumlah$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterStatusJumlah(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
    body: AdminMasterStatusJumlah
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}> {

    return this.putMasterStatusJumlah$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusJumlah;
})
    );
  }

  /**
   * Path part for operation deleteMasterStatusJumlah
   */
  static readonly DeleteMasterStatusJumlahPath = '/admin/masterStatusJumlahs/{id}';

  /**
   * Store new Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMasterStatusJumlah()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterStatusJumlah$Response(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusJumlahService.DeleteMasterStatusJumlahPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_status_jumlah.
   *
   * Returns Master_status_jumlah data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMasterStatusJumlah$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterStatusJumlah(params: {

    /**
     * id of Master_status_jumlah
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteMasterStatusJumlah$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
