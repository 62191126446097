/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientHayatiByHabitat } from '../models/client-hayati-by-habitat';

@Injectable({
  providedIn: 'root',
})
export class ClientHayatiByHabitatService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientHayatiByHabitatsList
   */
  static readonly GetclientHayatiByHabitatsListPath = '/client/hayatiByHabitats';

  /**
   * Get list of hayatiByHabitats.
   *
   * Returns list of hayatiByHabitats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByHabitatsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByHabitatsList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByHabitat>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByHabitatService.GetclientHayatiByHabitatsListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientHayatiByHabitat>;
        }>;
      })
    );
  }

  /**
   * Get list of hayatiByHabitats.
   *
   * Returns list of hayatiByHabitats
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByHabitatsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByHabitatsList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByHabitat>;
}> {

    return this.getclientHayatiByHabitatsList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByHabitat>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByHabitat>;
})
    );
  }

  /**
   * Path part for operation storeclientHayatiByHabitat
   */
  static readonly StoreclientHayatiByHabitatPath = '/client/hayatiByHabitats';

  /**
   * Store new Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientHayatiByHabitat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByHabitat$Response(params: {
    body: ClientHayatiByHabitat
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByHabitatService.StoreclientHayatiByHabitatPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByHabitat;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientHayatiByHabitat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByHabitat(params: {
    body: ClientHayatiByHabitat
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}> {

    return this.storeclientHayatiByHabitat$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
})
    );
  }

  /**
   * Path part for operation getclientHayatiByHabitatId
   */
  static readonly GetclientHayatiByHabitatIdPath = '/client/hayatiByHabitats/{id}';

  /**
   * Get  Hayati_by_habitat by id.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByHabitatId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByHabitatId$Response(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByHabitatService.GetclientHayatiByHabitatIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByHabitat;
        }>;
      })
    );
  }

  /**
   * Get  Hayati_by_habitat by id.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByHabitatId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByHabitatId(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}> {

    return this.getclientHayatiByHabitatId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
})
    );
  }

  /**
   * Path part for operation putclientHayatiByHabitat
   */
  static readonly PutclientHayatiByHabitatPath = '/client/hayatiByHabitats/{id}';

  /**
   * Update Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientHayatiByHabitat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByHabitat$Response(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
    body: ClientHayatiByHabitat
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByHabitatService.PutclientHayatiByHabitatPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByHabitat;
        }>;
      })
    );
  }

  /**
   * Update Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientHayatiByHabitat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByHabitat(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
    body: ClientHayatiByHabitat
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}> {

    return this.putclientHayatiByHabitat$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByHabitat;
})
    );
  }

  /**
   * Path part for operation deleteclientHayatiByHabitat
   */
  static readonly DeleteclientHayatiByHabitatPath = '/client/hayatiByHabitats/{id}';

  /**
   * Store new Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientHayatiByHabitat()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByHabitat$Response(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByHabitatService.DeleteclientHayatiByHabitatPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_habitat.
   *
   * Returns Hayati_by_habitat data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientHayatiByHabitat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByHabitat(params: {

    /**
     * id of Hayati_by_habitat
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientHayatiByHabitat$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
