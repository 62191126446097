<!-- Default -->
<div
    class="app-area"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3'}"
>
    <div class="container">
        <div class="app-inner overflow-hidden">
            <div class="row">
                <div class="col-lg-6 order-2 order-lg-1 col-md-12">
                    <div class="app-image" data-aos="fade-right" data-aos-duration="500">
                        <img src="assets/images/mobile-app.png" alt="app-image">
                    </div>
                </div>
                <div class="col-lg-6 order-1 order-lg-2 col-md-12">
                    <div class="app-content" data-aos="fade-left" data-aos-duration="500">
                        <h2>
                            Download our apps today!
                        </h2>
                        <p>
                            Experience the power of convenience and stay connected to your job search journey with our dedicated mobile apps. Download now and unlock a world of opportunities right at your fingertips.
                        </p>
                        <h4 class="fw-semibold">
                            Why Download Our Apps?
                        </h4>
                        <ul class="features-list ps-0 list-unstyled">
                            <li class="position-relative fw-medium">
                                <i class="ri-check-line"></i>
                                Get instant job notification
                            </li>
                            <li class="position-relative fw-medium">
                                <i class="ri-check-line"></i>
                                Apply direct from the job app
                            </li>
                            <li class="position-relative fw-medium">
                                <i class="ri-check-line"></i>
                                Effortless navigation
                            </li>
                            <li class="position-relative fw-medium">
                                <i class="ri-check-line"></i>
                                Save favorites
                            </li>
                        </ul>
                        <div class="btn-box d-flex align-items-center">
                            <a href="#" target="_blank" class="d-block">
                                <img src="assets/images/google-play.jpg" alt="google-play">
                            </a>
                            <a href="#" target="_blank" class="d-block">
                                <img src="assets/images/app-store.jpg" alt="app-store">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 2 -->
<div
    class="app-area pt-100"
    *ngIf="router.url === '/index-2'"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-2 order-lg-1 col-md-12" data-aos="fade-up" data-aos-duration="500">
                <div class="app-image style-two">
                    <img src="assets/images/mobile-app2.png" alt="app-image">
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 col-md-12" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                <div class="app-content style-two">
                    <h2>
                        Download our apps today!
                    </h2>
                    <p>
                        Experience the power of convenience and stay connected to your job search journey with our dedicated mobile apps. Download now and unlock a world of opportunities right at your fingertips.
                    </p>
                    <h4 class="fw-semibold">
                        Why Download Our Apps?
                    </h4>
                    <ul class="features-list ps-0 list-unstyled">
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Get instant job notification
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Apply direct from the job app
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Effortless navigation
                        </li>
                        <li class="position-relative fw-medium">
                            <i class="ri-check-line"></i>
                            Save favorites
                        </li>
                    </ul>
                    <div class="btn-box d-flex align-items-center">
                        <a href="#" target="_blank" class="d-block">
                            <img src="assets/images/google-play2.jpg" alt="google-play">
                        </a>
                        <a href="#" target="_blank" class="d-block">
                            <img src="assets/images/app-store2.jpg" alt="app-store">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 3 -->
<div
    class="app-area ptb-100 bg-color"
    style="background-image: url(assets/images/banner/banner-bg3.jpg);"
    *ngIf="router.url === '/index-3'"
>
    <div class="container">
        <div class="app-text mx-auto text-center">
            <h2 data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                Download Jove.com App
            </h2>
            <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                Experience the power of convenience and stay connected to your job search journey with our dedicated mobile apps. Download now and unlock a world of opportunities right at your fingertips.
            </p>
            <h3 class="fw-semibold" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                You can download this app on your phone for FREE
            </h3>
            <div class="btn-box justify-content-center d-flex align-items-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/images/google-play.jpg" alt="google-play">
                </a>
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/images/app-store.jpg" alt="app-store">
                </a>
            </div>
        </div>
    </div>
</div>