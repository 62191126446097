import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-footer',
  templateUrl: './cd-footer.component.html',
  styleUrls: ['./cd-footer.component.scss']
})
export class CdFooterComponent {

}
