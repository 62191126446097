<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    About Us
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        About Us
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<div class="pt-100">
    <app-leading-company></app-leading-company>
</div>

<app-about-us></app-about-us>

<div class="pt-100">
    <app-funfacts></app-funfacts>
</div>

<app-how-jove-works></app-how-jove-works>

<app-talented-experts></app-talented-experts>

<app-testimonials></app-testimonials>

<div class="pb-100">
    <app-download-app></app-download-app>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>