/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientHayatiByFamili } from '../models/client-hayati-by-famili';

@Injectable({
  providedIn: 'root',
})
export class ClientHayatiByFamiliService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientHayatiByFamilisList
   */
  static readonly GetclientHayatiByFamilisListPath = '/client/hayatiByFamilis';

  /**
   * Get list of hayatiByFamilis.
   *
   * Returns list of hayatiByFamilis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByFamilisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByFamilisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByFamili>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByFamiliService.GetclientHayatiByFamilisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientHayatiByFamili>;
        }>;
      })
    );
  }

  /**
   * Get list of hayatiByFamilis.
   *
   * Returns list of hayatiByFamilis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByFamilisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByFamilisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByFamili>;
}> {

    return this.getclientHayatiByFamilisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByFamili>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByFamili>;
})
    );
  }

  /**
   * Path part for operation storeclientHayatiByFamili
   */
  static readonly StoreclientHayatiByFamiliPath = '/client/hayatiByFamilis';

  /**
   * Store new Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientHayatiByFamili()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByFamili$Response(params: {
    body: ClientHayatiByFamili
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByFamiliService.StoreclientHayatiByFamiliPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByFamili;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientHayatiByFamili$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByFamili(params: {
    body: ClientHayatiByFamili
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}> {

    return this.storeclientHayatiByFamili$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
})
    );
  }

  /**
   * Path part for operation getclientHayatiByFamiliId
   */
  static readonly GetclientHayatiByFamiliIdPath = '/client/hayatiByFamilis/{id}';

  /**
   * Get  Hayati_by_famili by id.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByFamiliId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByFamiliId$Response(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByFamiliService.GetclientHayatiByFamiliIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByFamili;
        }>;
      })
    );
  }

  /**
   * Get  Hayati_by_famili by id.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByFamiliId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByFamiliId(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}> {

    return this.getclientHayatiByFamiliId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
})
    );
  }

  /**
   * Path part for operation putclientHayatiByFamili
   */
  static readonly PutclientHayatiByFamiliPath = '/client/hayatiByFamilis/{id}';

  /**
   * Update Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientHayatiByFamili()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByFamili$Response(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
    body: ClientHayatiByFamili
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByFamiliService.PutclientHayatiByFamiliPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByFamili;
        }>;
      })
    );
  }

  /**
   * Update Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientHayatiByFamili$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByFamili(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
    body: ClientHayatiByFamili
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}> {

    return this.putclientHayatiByFamili$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByFamili;
})
    );
  }

  /**
   * Path part for operation deleteclientHayatiByFamili
   */
  static readonly DeleteclientHayatiByFamiliPath = '/client/hayatiByFamilis/{id}';

  /**
   * Store new Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientHayatiByFamili()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByFamili$Response(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByFamiliService.DeleteclientHayatiByFamiliPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_famili.
   *
   * Returns Hayati_by_famili data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientHayatiByFamili$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByFamili(params: {

    /**
     * id of Hayati_by_famili
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientHayatiByFamili$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
