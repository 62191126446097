<!-- Default -->
<div
    class="how-jove-works-area bg-f8f8fb pt-100 pb-75"
    [ngClass]="{'d-none': router.url === '/index-2'}"
>
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                How Jove works for you
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col" data-aos="fade-in" data-aos-duration="500">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/search.png" alt="search-icon">
                    </div>
                    <div class="number">
                        01
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Search
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/discover.png" alt="discover-icon">
                    </div>
                    <div class="number">
                        02
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Discover
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/apply.png" alt="apply-icon">
                    </div>
                    <div class="number">
                        03
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Apply
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/nodes.png" alt="nodes-icon">
                    </div>
                    <div class="number">
                        04
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Connect
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/success.png" alt="success-icon">
                    </div>
                    <div class="number">
                        05
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Achieve
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 2 -->
<div
    class="how-jove-works-area bg-color pt-100 pb-75"
    *ngIf="router.url === '/index-2'"
>
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                How Jove works for you
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col" data-aos="fade-in" data-aos-duration="500">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/search-2.svg" alt="search-icon">
                    </div>
                    <div class="number">
                        01
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Search
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/discover-2.svg" alt="discover-icon">
                    </div>
                    <div class="number">
                        02
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Discover
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/apply-2.svg" alt="apply-icon">
                    </div>
                    <div class="number">
                        03
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Apply
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/nodes-2.svg" alt="nodes-icon">
                    </div>
                    <div class="number">
                        04
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Connect
                    </h3>
                </div>
            </div>
            <div class="col" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="box position-relative text-center">
                    <div class="icon">
                        <img src="assets/images/icons/success-2.svg" alt="success-icon">
                    </div>
                    <div class="number">
                        05
                    </div>
                    <h3 class="mb-0 fw-semibold">
                        Achieve
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>