<div
    class="banner-area position-relative z-1 overflow-hidden"
    style="background-image: url(assets/images/banner/banner-bg2.jpg);"
>
    <div class="container-fluid">
        <div class="banner-content position-relative text-center">
            <img src="assets/images/banner/banner1.png" alt="banner-image">
            <div class="banner-content-form mx-auto" data-aos="fade-up" data-aos-duration="500">
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="fw-medium">Find the right job</span>
                    <span class="fw-medium">Looking to hire talent?</span>
                </div>
                <form class="banner-form">
                    <div class="row mx-0 align-items-center">
                        <div class="col-4 px-0">
                            <div class="form-group position-relative">
                                <i class="ri-search-line"></i>
                                <input type="text" class="form-control" placeholder="Job title, key words or company">
                            </div>
                        </div>
                        <div class="col-4 px-0">
                            <div class="form-group position-relative">
                                <i class="ri-map-pin-line"></i>
                                <select class="form-select">
                                    <option selected>All Location</option>
                                    <option value="1">Tokyo, Japan</option>
                                    <option value="2">New York, USA</option>
                                    <option value="3">London, England</option>
                                    <option value="4">Beijing, China</option>
                                    <option value="5">Toronto, Canada</option>
                                    <option value="6">Chicago, USA</option>
                                    <option value="7">Hong Kong, China</option>
                                    <option value="8">Los Angeles, USA</option>
                                    <option value="9">São Paulo, Brazil</option>
                                    <option value="10">Seoul, South Korea</option>
                                    <option value="11">Barcelona, Spain</option>
                                    <option value="12">Istanbul, Turkey</option>
                                    <option value="13">Osaka, Japan</option>
                                    <option value="14">Copenhagen, Denmark</option>
                                    <option value="15">Madrid, Spain</option>
                                    <option value="16">Bangkok, Thailand</option>
                                    <option value="17">Dubai, UAE</option>
                                    <option value="18">Melbourne, Australia</option>
                                    <option value="19">Amsterdam, Netherlands</option>
                                    <option value="20">Paris, France</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4 px-0">
                            <button type="submit" class="default-btn two">
                                Search Jobs
                            </button>
                        </div>
                    </div>
                </form>
                <p>
                    Search 10,000 new jobs - 500+ added in the last 7 days
                </p>
            </div>
        </div>
    </div>
    <div class="text-slides" data-aos="fade-down" data-aos-duration="500">
        <div>
            <span>Enter the new world through jobs .</span> Enter the new world through jobs
        </div>
    </div>
</div>

<!-- Popular Searches -->
<div class="popular-searches-area">
    <div class="container">
        <h2 class="fw-semibold">
            Popular searches
        </h2>
        <ul class="ps-0 mb-0 list-unstyled">
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Part-Time
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Marketing
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Design
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Development
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Internships
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Remote
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="600">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Sales
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="700">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Business
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="800">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    IT
                </a>
            </li>
            <li class="d-inline-block" data-aos="fade-in" data-aos-duration="500" data-aos-delay="900">
                <a routerLink="/" class="d-block fw-medium">
                    <i class="ri-search-line"></i>
                    Engineering
                </a>
            </li>
        </ul>
    </div>
</div>