import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-applied-jobs',
  templateUrl: './cd-applied-jobs.component.html',
  styleUrls: ['./cd-applied-jobs.component.scss']
})
export class CdAppliedJobsComponent {

}
