import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-profile',
  templateUrl: './cd-profile.component.html',
  styleUrls: ['./cd-profile.component.scss']
})
export class CdProfileComponent {

}
