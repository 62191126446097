import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-message',
  templateUrl: './ed-message.component.html',
  styleUrls: ['./ed-message.component.scss']
})
export class EdMessageComponent {

}
