<div
    class="bg-image"
    style="background-image: url(assets/images/banner/banner-bg1.png);"
>

    <!-- Banner Area -->
    <div class="banner-area position-relative ptb-100 z-1 overflow-hidden">
        <div class="container">
            <div class="banner-content text-center mx-auto" data-aos="fade-up" data-aos-duration="500">
                <h1>
                    Enter the new world <span>through jobs</span>
                </h1>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="fw-medium">Find the right job</span>
                    <span class="fw-medium">Looking to hire talent?</span>
                </div>
                <form class="banner-form">
                    <div class="row mx-0 align-items-center">
                        <div class="col-4 px-0">
                            <div class="form-group position-relative">
                                <i class="ri-search-line"></i>
                                <input type="text" class="form-control" placeholder="Job title, key words or company">
                            </div>
                        </div>
                        <div class="col-4 px-0">
                            <div class="form-group position-relative">
                                <i class="ri-map-pin-line"></i>
                                <select class="form-select">
                                    <option selected>All Location</option>
                                    <option value="1">Tokyo, Japan</option>
                                    <option value="2">New York, USA</option>
                                    <option value="3">London, England</option>
                                    <option value="4">Beijing, China</option>
                                    <option value="5">Toronto, Canada</option>
                                    <option value="6">Chicago, USA</option>
                                    <option value="7">Hong Kong, China</option>
                                    <option value="8">Los Angeles, USA</option>
                                    <option value="9">São Paulo, Brazil</option>
                                    <option value="10">Seoul, South Korea</option>
                                    <option value="11">Barcelona, Spain</option>
                                    <option value="12">Istanbul, Turkey</option>
                                    <option value="13">Osaka, Japan</option>
                                    <option value="14">Copenhagen, Denmark</option>
                                    <option value="15">Madrid, Spain</option>
                                    <option value="16">Bangkok, Thailand</option>
                                    <option value="17">Dubai, UAE</option>
                                    <option value="18">Melbourne, Australia</option>
                                    <option value="19">Amsterdam, Netherlands</option>
                                    <option value="20">Paris, France</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4 px-0">
                            <button type="submit" class="default-btn">
                                Search Jobs
                            </button>
                        </div>
                    </div>
                </form>
                <div class="upload-cv-btn">
                    <a routerLink="/" class="fw-medium">
                        Upload your CV
                    </a>
                </div>
            </div>
        </div>
        <div class="users-list">
            <img src="assets/images/users/user1.png" class="rounded-circle" alt="user-image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
            <img src="assets/images/users/user2.png" class="rounded-circle" alt="user-image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src="assets/images/users/user3.png" class="rounded-circle" alt="user-image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
            <img src="assets/images/users/user4.png" class="rounded-circle" alt="user-image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
        </div>
    </div>

    <!-- Banner Jobs Categories -->
    <div class="banner-jobs-categories position-relative z-1">
        <div class="container">
            <div class="inner" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                <h2 class="text-center">
                    Search 10,000 new jobs - 500+ added in the last 7 days
                </h2>
                <div class="wrap">
                    <div class="row mx-0">
                        <div class="col-lg-4 col-md-6 px-0">
                            <div class="box">
                                <h3 class="fw-semibold">
                                    Jobs by Industry
                                </h3>
                                <ul class="mb-0 list-unstyled">
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Accountancy & Accounting
                                            <span>(126)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Admin & Administration
                                            <span>(65)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Automotive
                                            <span>(29)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Banking
                                            <span>(86)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Beauty Care
                                            <span>(71)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Cleaning
                                            <span>(19)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Charity & Volunteer
                                            <span>(13)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Construction
                                            <span>(142)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 px-0">
                            <div class="box">
                                <h3 class="fw-semibold">
                                    Special skilled jobs
                                </h3>
                                <ul class="mb-0 list-unstyled">
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Commercial
                                            <span>(76)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Design & Creative
                                            <span>(125)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Education & Training
                                            <span>(149)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Engineering & Architects
                                            <span>(51)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Environmental
                                            <span>(29)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Finance
                                            <span>(76)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Healthcare & Medicine
                                            <span>(83)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Hospitality
                                            <span>(127)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 px-0">
                            <div class="box">
                                <h3 class="fw-semibold">
                                    Popular searches jobs
                                </h3>
                                <ul class="mb-0 list-unstyled">
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Insurance
                                            <span>(49)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            IT
                                            <span>(214)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Legal
                                            <span>(18)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            NGO
                                            <span>(99)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Sports
                                            <span>(37)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Retail
                                            <span>(86)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Supply Chain
                                            <span>(42)</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/" class="d-flex justify-content-between align-items-center">
                                            Tourism
                                            <span>(135)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>