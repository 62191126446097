import { Component } from '@angular/core';

@Component({
  selector: 'app-cd-bookmarks',
  templateUrl: './cd-bookmarks.component.html',
  styleUrls: ['./cd-bookmarks.component.scss']
})
export class CdBookmarksComponent {

}
