<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Categories
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Categories
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Categories Style - 1 -->
<div class="categories-area pt-100 pb-75">
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Categories style - 1
            </h2>
        </div>
        <div class="row">
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/technology-green.svg" alt="technology">
                    <h3 class="fw-semibold">
                        Technology
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/healthcare-green.svg" alt="healthcare">
                    <h3 class="fw-semibold">
                        Healthcare
                    </h3>
                    <span class="d-block">
                        (18)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/finance-green.svg" alt="finance">
                    <h3 class="fw-semibold">
                        Finance
                    </h3>
                    <span class="d-block">
                        (43)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/engineering-green.svg" alt="engineering">
                    <h3 class="fw-semibold">
                        Engineering
                    </h3>
                    <span class="d-block">
                        (22)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/education-green.svg" alt="education">
                    <h3 class="fw-semibold">
                        Education
                    </h3>
                    <span class="d-block">
                        (29)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/design-green.svg" alt="design">
                    <h3 class="fw-semibold">
                        Design
                    </h3>
                    <span class="d-block">
                        (65)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/accounting-green.svg" alt="accounting">
                    <h3 class="fw-semibold">
                        Accounting
                    </h3>
                    <span class="d-block">
                        (34)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/tourism-green.svg" alt="tourism">
                    <h3 class="fw-semibold">
                        Tourism
                    </h3>
                    <span class="d-block">
                        (15)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/administrative-green.svg" alt="administrative">
                    <h3 class="fw-semibold">
                        Administrative
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/manufacturing-green.svg" alt="manufacturing">
                    <h3 class="fw-semibold">
                        Manufacturing
                    </h3>
                    <span class="d-block">
                        (05)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/non-profit-green.svg" alt="non-profit">
                    <h3 class="fw-semibold">
                        Non-Profit
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <div class="single-category-box text-center position-relative">
                    <img src="assets/images/icons/marketing-green.svg" alt="marketing">
                    <h3 class="fw-semibold">
                        Marketing
                    </h3>
                    <span class="d-block">
                        (46)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Categories Style - 2 -->
<div class="categories-area pb-75">
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Categories style - 2
            </h2>
        </div>
        <div class="row">
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/technology.svg" alt="technology">
                    <h3 class="fw-semibold">
                        Technology
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/healthcare.svg" alt="healthcare">
                    <h3 class="fw-semibold">
                        Healthcare
                    </h3>
                    <span class="d-block">
                        (18)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/finance.svg" alt="finance">
                    <h3 class="fw-semibold">
                        Finance
                    </h3>
                    <span class="d-block">
                        (43)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/engineering.svg" alt="engineering">
                    <h3 class="fw-semibold">
                        Engineering
                    </h3>
                    <span class="d-block">
                        (22)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/education.svg" alt="education">
                    <h3 class="fw-semibold">
                        Education
                    </h3>
                    <span class="d-block">
                        (29)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/design.svg" alt="design">
                    <h3 class="fw-semibold">
                        Design
                    </h3>
                    <span class="d-block">
                        (65)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/accounting.svg" alt="accounting">
                    <h3 class="fw-semibold">
                        Accounting
                    </h3>
                    <span class="d-block">
                        (34)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/tourism.svg" alt="tourism">
                    <h3 class="fw-semibold">
                        Tourism
                    </h3>
                    <span class="d-block">
                        (15)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/administrative.svg" alt="administrative">
                    <h3 class="fw-semibold">
                        Administrative
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/manufacturing.svg" alt="manufacturing">
                    <h3 class="fw-semibold">
                        Manufacturing
                    </h3>
                    <span class="d-block">
                        (05)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="400">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/non-profit.svg" alt="non-profit">
                    <h3 class="fw-semibold">
                        Non-Profit
                    </h3>
                    <span class="d-block">
                        (10)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-2 col-lg-3 col-6 col-sm-4" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
                <div class="single-category-box style-two text-center position-relative">
                    <img src="assets/images/icons/marketing.svg" alt="marketing">
                    <h3 class="fw-semibold">
                        Marketing
                    </h3>
                    <span class="d-block">
                        (46)
                    </span>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Categories Style - 3 -->
<div class="categories-area pb-75">
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Categories style - 3
            </h2>
        </div>
        <div class="row">
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/technology.svg" alt="technology">
                        </div>
                        <h3 class="fw-semibold">
                            Technology <span>(10)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/healthcare.svg" alt="healthcare">
                        </div>
                        <h3 class="fw-semibold">
                            Healthcare <span>(18)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/finance.svg" alt="finance">
                        </div>
                        <h3 class="fw-semibold">
                            Finance <span>(43)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/engineering.svg" alt="engineering">
                        </div>
                        <h3 class="fw-semibold">
                            Engineering <span>(22)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/education.svg" alt="education">
                        </div>
                        <h3 class="fw-semibold">
                            Education <span>(29)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/design.svg" alt="design">
                        </div>
                        <h3 class="fw-semibold">
                            Design <span>(65)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/accounting.svg" alt="accounting">
                        </div>
                        <h3 class="fw-semibold">
                            Accounting <span>(34)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/tourism.svg" alt="tourism">
                        </div>
                        <h3 class="fw-semibold">
                            Tourism <span>(15)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/administrative.svg" alt="administrative">
                        </div>
                        <h3 class="fw-semibold">
                            Administrative <span>(10)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/manufacturing.svg" alt="manufacturing">
                        </div>
                        <h3 class="fw-semibold">
                            Manufacturing <span>(05)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/non-profit.svg" alt="non-profit">
                        </div>
                        <h3 class="fw-semibold">
                            Non-Profit <span>(10)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
            <div class="col-xxl-3 col-lg-4 col-6 col-sm-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300">
                <div class="single-category-box style-three position-relative">
                    <div class="d-md-flex align-items-center">
                        <div>
                            <img src="assets/images/icons/marketing.svg" alt="marketing">
                        </div>
                        <h3 class="fw-semibold">
                            Marketing <span>(46)</span>
                        </h3>
                    </div>
                    <a routerLink="/jobs-grid" class="position-absolute start-0 top-0 bottom-0 end-0 z-1"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>