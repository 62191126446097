<!-- Default Style -->
<div
    class="about-area ptb-100 bg-faf5f2"
    [ngClass]="{'d-none': router.url === '/index-3'}"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="500">
                <div class="about-image text-center">
                    <img src="assets/images/about.png" alt="about-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                <div class="about-content">
                    <h2>
                        How jobs board “Jove” works
                    </h2>
                    <p>
                        A job board is an online platform that connects job seekers with job opportunities posted by employers, recruiters, or companies. The primary purpose of a job board is to facilitate the job search and hiring process by providing a centralized place for job listings and candidate applications.
                    </p>
                    <div class="box position-relative">
                        <img src="assets/images/icons/analyze.svg" alt="analyze-icon">
                        <h3 class="fw-semibold">
                            Search & analyze
                        </h3>
                        <p>
                            Job seekers visit the job board platform and search for relevant job listings. They can use filters such as keywords, location, industry, and job type to narrow down their search.
                        </p>
                    </div>
                    <div class="box position-relative">
                        <img src="assets/images/icons/accept.svg" alt="accept-icon">
                        <h3 class="fw-semibold">
                            Compare & quick apply
                        </h3>
                        <p>
                            Job seekers visit the job board platform and search for relevant job listings. They can use filters such as keywords, location, industry, and job type to narrow down their search.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 3 -->
<div
    class="about-area bg-e9e7e7 position-relative z-1 pt-100"
    *ngIf="router.url === '/index-3'"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1" data-aos="fade-in" data-aos-duration="500">
                <div class="about-img position-relative z-1 text-center">
                    <img src="assets/images/woman-smile.jpg" alt="about-image">
                    <img src="assets/images/shapes/shape4.png" class="shape" alt="shape-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="about-text">
                    <div class="about-content">
                        <h2>
                            How jobs board “Jove” works
                        </h2>
                        <p>
                            A job board is an online platform that connects job seekers with job opportunities posted by employers, recruiters, or companies. The primary purpose of a job board is to facilitate the job search and hiring process by providing a centralized place for job listings and candidate applications.
                        </p>
                        <div class="box position-relative">
                            <img src="assets/images/icons/analyze-green.svg" alt="analyze-icon">
                            <h3 class="fw-semibold">
                                Search & analyze
                            </h3>
                            <p>
                                Job seekers visit the job board platform and search for relevant job listings. They can use filters such as keywords, location, industry, and job type to narrow down their search.
                            </p>
                        </div>
                        <div class="box position-relative">
                            <img src="assets/images/icons/accept-green.svg" alt="accept-icon">
                            <h3 class="fw-semibold">
                                Compare & quick apply
                            </h3>
                            <p>
                                Job seekers visit the job board platform and search for relevant job listings. They can use filters such as keywords, location, industry, and job type to narrow down their search.
                            </p>
                        </div>
                    </div>
                    <div class="funfacts-box">
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-6">
                                <div class="funfact-box">
                                    <h2>
                                        5.1M
                                        <sup>
                                            +
                                        </sup>
                                    </h2>
                                    <span class="d-block">
                                        Jobs registered
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-6">
                                <div class="funfact-box">
                                    <h2>
                                        4M
                                        <sup>
                                            +
                                        </sup>
                                    </h2>
                                    <span class="d-block">
                                        Daily active users
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-6">
                                <div class="funfact-box">
                                    <h2>
                                        11K
                                        <sup>
                                            +
                                        </sup>
                                    </h2>
                                    <span class="d-block">
                                        Open job positions
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>