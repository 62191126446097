<app-navbar></app-navbar>

<app-homeone-banner></app-homeone-banner>

<app-companies></app-companies>

<app-categories></app-categories>

<app-features></app-features>

<app-funfacts></app-funfacts>

<app-popular-jobs></app-popular-jobs>

<app-how-jove-works></app-how-jove-works>

<app-jobs-by-location></app-jobs-by-location>

<app-testimonials></app-testimonials>

<app-get-hired-by-top-companies></app-get-hired-by-top-companies>

<app-faq></app-faq>

<app-download-app></app-download-app>

<app-blog></app-blog>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>