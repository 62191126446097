/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterStatusPerlindungan } from '../models/admin-master-status-perlindungan';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterStatusPerlindunganService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getmasterStatusPerlindungansList
   */
  static readonly GetmasterStatusPerlindungansListPath = '/admin/masterStatusPerlindungans';

  /**
   * Get list of masterStatusPerlindungans.
   *
   * Returns list of masterStatusPerlindungans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getmasterStatusPerlindungansList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterStatusPerlindungansList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusPerlindungan>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusPerlindunganService.GetmasterStatusPerlindungansListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterStatusPerlindungan>;
        }>;
      })
    );
  }

  /**
   * Get list of masterStatusPerlindungans.
   *
   * Returns list of masterStatusPerlindungans
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getmasterStatusPerlindungansList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterStatusPerlindungansList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusPerlindungan>;
}> {

    return this.getmasterStatusPerlindungansList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusPerlindungan>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterStatusPerlindungan>;
})
    );
  }

  /**
   * Path part for operation storeMasterStatusPerlindungan
   */
  static readonly StoreMasterStatusPerlindunganPath = '/admin/masterStatusPerlindungans';

  /**
   * Store new Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeMasterStatusPerlindungan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterStatusPerlindungan$Response(params: {
    body: AdminMasterStatusPerlindungan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusPerlindunganService.StoreMasterStatusPerlindunganPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusPerlindungan;
        }>;
      })
    );
  }

  /**
   * Store new Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeMasterStatusPerlindungan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterStatusPerlindungan(params: {
    body: AdminMasterStatusPerlindungan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}> {

    return this.storeMasterStatusPerlindungan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
})
    );
  }

  /**
   * Path part for operation getMasterStatusPerlindunganId
   */
  static readonly GetMasterStatusPerlindunganIdPath = '/admin/masterStatusPerlindungans/{id}';

  /**
   * Get  Master_status_perlindungan by id.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterStatusPerlindunganId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterStatusPerlindunganId$Response(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusPerlindunganService.GetMasterStatusPerlindunganIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusPerlindungan;
        }>;
      })
    );
  }

  /**
   * Get  Master_status_perlindungan by id.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMasterStatusPerlindunganId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterStatusPerlindunganId(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}> {

    return this.getMasterStatusPerlindunganId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
})
    );
  }

  /**
   * Path part for operation putMasterStatusPerlindungan
   */
  static readonly PutMasterStatusPerlindunganPath = '/admin/masterStatusPerlindungans/{id}';

  /**
   * Update Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMasterStatusPerlindungan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterStatusPerlindungan$Response(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
    body: AdminMasterStatusPerlindungan
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusPerlindunganService.PutMasterStatusPerlindunganPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterStatusPerlindungan;
        }>;
      })
    );
  }

  /**
   * Update Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMasterStatusPerlindungan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterStatusPerlindungan(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
    body: AdminMasterStatusPerlindungan
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}> {

    return this.putMasterStatusPerlindungan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterStatusPerlindungan;
})
    );
  }

  /**
   * Path part for operation deleteMasterStatusPerlindungan
   */
  static readonly DeleteMasterStatusPerlindunganPath = '/admin/masterStatusPerlindungans/{id}';

  /**
   * Store new Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMasterStatusPerlindungan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterStatusPerlindungan$Response(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterStatusPerlindunganService.DeleteMasterStatusPerlindunganPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_status_perlindungan.
   *
   * Returns Master_status_perlindungan data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMasterStatusPerlindungan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterStatusPerlindungan(params: {

    /**
     * id of Master_status_perlindungan
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteMasterStatusPerlindungan$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
