<div class="not-found-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="not-found-content text-center">
                    <img src="assets/images/error.png" alt="error-image">
                    <h3>Oops! That page can't be found</h3>
                    <p class="mx-auto">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <a routerLink="/" class="default-btn">Back to Home</a>
                </div>
            </div>
        </div>
    </div>
</div>