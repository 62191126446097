/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientHayatiByBudidaya } from '../models/client-hayati-by-budidaya';

@Injectable({
  providedIn: 'root',
})
export class ClientHayatiByBudidayaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getclientHayatiByBudidayasList
   */
  static readonly GetclientHayatiByBudidayasListPath = '/client/hayatiByBudidayas';

  /**
   * Get list of hayatiByBudidayas.
   *
   * Returns list of hayatiByBudidayas
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByBudidayasList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByBudidayasList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByBudidaya>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByBudidayaService.GetclientHayatiByBudidayasListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<ClientHayatiByBudidaya>;
        }>;
      })
    );
  }

  /**
   * Get list of hayatiByBudidayas.
   *
   * Returns list of hayatiByBudidayas
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByBudidayasList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByBudidayasList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByBudidaya>;
}> {

    return this.getclientHayatiByBudidayasList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByBudidaya>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<ClientHayatiByBudidaya>;
})
    );
  }

  /**
   * Path part for operation storeclientHayatiByBudidaya
   */
  static readonly StoreclientHayatiByBudidayaPath = '/client/hayatiByBudidayas';

  /**
   * Store new Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeclientHayatiByBudidaya()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByBudidaya$Response(params: {
    body: ClientHayatiByBudidaya
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByBudidayaService.StoreclientHayatiByBudidayaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByBudidaya;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeclientHayatiByBudidaya$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeclientHayatiByBudidaya(params: {
    body: ClientHayatiByBudidaya
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}> {

    return this.storeclientHayatiByBudidaya$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
})
    );
  }

  /**
   * Path part for operation getclientHayatiByBudidayaId
   */
  static readonly GetclientHayatiByBudidayaIdPath = '/client/hayatiByBudidayas/{id}';

  /**
   * Get  Hayati_by_budidaya by id.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getclientHayatiByBudidayaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByBudidayaId$Response(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByBudidayaService.GetclientHayatiByBudidayaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByBudidaya;
        }>;
      })
    );
  }

  /**
   * Get  Hayati_by_budidaya by id.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getclientHayatiByBudidayaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getclientHayatiByBudidayaId(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}> {

    return this.getclientHayatiByBudidayaId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
})
    );
  }

  /**
   * Path part for operation putclientHayatiByBudidaya
   */
  static readonly PutclientHayatiByBudidayaPath = '/client/hayatiByBudidayas/{id}';

  /**
   * Update Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putclientHayatiByBudidaya()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByBudidaya$Response(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
    body: ClientHayatiByBudidaya
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByBudidayaService.PutclientHayatiByBudidayaPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: ClientHayatiByBudidaya;
        }>;
      })
    );
  }

  /**
   * Update Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putclientHayatiByBudidaya$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putclientHayatiByBudidaya(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
    body: ClientHayatiByBudidaya
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}> {

    return this.putclientHayatiByBudidaya$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: ClientHayatiByBudidaya;
})
    );
  }

  /**
   * Path part for operation deleteclientHayatiByBudidaya
   */
  static readonly DeleteclientHayatiByBudidayaPath = '/client/hayatiByBudidayas/{id}';

  /**
   * Store new Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteclientHayatiByBudidaya()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByBudidaya$Response(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientHayatiByBudidayaService.DeleteclientHayatiByBudidayaPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Hayati_by_budidaya.
   *
   * Returns Hayati_by_budidaya data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteclientHayatiByBudidaya$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteclientHayatiByBudidaya(params: {

    /**
     * id of Hayati_by_budidaya
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteclientHayatiByBudidaya$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
