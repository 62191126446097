<!-- Default -->
<div
    class="funfacts-area pb-75"
    [ngClass]="{'d-none': router.url === '/index-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500">
                <div class="funfact-box text-center">
                    <h2>
                        5.1M
                        <sup>
                            +
                        </sup>
                    </h2>
                    <span class="d-block">
                        Jobs registered on our system
                    </span>
                </div>
            </div>
            <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="funfact-box text-center">
                    <h2>
                        4.2M
                        <sup>
                            +
                        </sup>
                    </h2>
                    <span class="d-block">
                        4 million daily active users
                    </span>
                </div>
            </div>
            <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="funfact-box text-center">
                    <h2>
                        11K
                        <sup>
                            +
                        </sup>
                    </h2>
                    <span class="d-block">
                        Over 10k open job positions
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Only for Home Demo - 2 -->
<div
    class="funfacts-area"
    *ngIf="router.url === '/index-2'"
>
    <div class="container">
        <div class="funfacts-inner pt-100 pb-75">
            <div class="row justify-content-center">
                <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500">
                    <div class="funfact-box text-center">
                        <h2>
                            5.1M
                            <sup>
                                +
                            </sup>
                        </h2>
                        <span class="d-block">
                            Jobs registered on our system
                        </span>
                    </div>
                </div>
                <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                    <div class="funfact-box text-center">
                        <h2>
                            4.2M
                            <sup>
                                +
                            </sup>
                        </h2>
                        <span class="d-block">
                            4 million daily active users
                        </span>
                    </div>
                </div>
                <div class="col-md-4 col-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                    <div class="funfact-box text-center">
                        <h2>
                            11K
                            <sup>
                                +
                            </sup>
                        </h2>
                        <span class="d-block">
                            Over 10k open job positions
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>