import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-footer',
  templateUrl: './ed-footer.component.html',
  styleUrls: ['./ed-footer.component.scss']
})
export class EdFooterComponent {

}
