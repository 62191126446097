import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-all-applicants',
  templateUrl: './ed-all-applicants.component.html',
  styleUrls: ['./ed-all-applicants.component.scss']
})
export class EdAllApplicantsComponent {

}
