/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminRole } from '../models/admin-role';

@Injectable({
  providedIn: 'root',
})
export class AdminRoleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getadminRolesList
   */
  static readonly GetadminRolesListPath = '/admin/roles';

  /**
   * Get list of roles.
   *
   * Returns list of roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminRolesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminRolesList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminRole>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminRoleService.GetadminRolesListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminRole>;
        }>;
      })
    );
  }

  /**
   * Get list of roles.
   *
   * Returns list of roles
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminRolesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminRolesList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminRole>;
}> {

    return this.getadminRolesList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminRole>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminRole>;
})
    );
  }

  /**
   * Path part for operation storeadminRole
   */
  static readonly StoreadminRolePath = '/admin/roles';

  /**
   * Store new Role.
   *
   * Returns Role data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeadminRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminRole$Response(params: {
    body: AdminRole
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminRoleService.StoreadminRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminRole;
        }>;
      })
    );
  }

  /**
   * Store new Role.
   *
   * Returns Role data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeadminRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeadminRole(params: {
    body: AdminRole
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}> {

    return this.storeadminRole$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
})
    );
  }

  /**
   * Path part for operation getadminRoleId
   */
  static readonly GetadminRoleIdPath = '/admin/roles/{id}';

  /**
   * Get  Role by id.
   *
   * Returns Role data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getadminRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminRoleId$Response(params: {

    /**
     * id of Role
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminRoleService.GetadminRoleIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminRole;
        }>;
      })
    );
  }

  /**
   * Get  Role by id.
   *
   * Returns Role data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getadminRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getadminRoleId(params: {

    /**
     * id of Role
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}> {

    return this.getadminRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
})
    );
  }

  /**
   * Path part for operation putadminRole
   */
  static readonly PutadminRolePath = '/admin/roles/{id}';

  /**
   * Update Role.
   *
   * Returns Role data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putadminRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminRole$Response(params: {

    /**
     * id of Role
     */
    id: string;
    body: AdminRole
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminRoleService.PutadminRolePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminRole;
        }>;
      })
    );
  }

  /**
   * Update Role.
   *
   * Returns Role data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putadminRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putadminRole(params: {

    /**
     * id of Role
     */
    id: string;
    body: AdminRole
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}> {

    return this.putadminRole$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminRole;
})
    );
  }

  /**
   * Path part for operation deleteadminRole
   */
  static readonly DeleteadminRolePath = '/admin/roles/{id}';

  /**
   * Store new Role.
   *
   * Returns Role data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteadminRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminRole$Response(params: {

    /**
     * id of Role
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminRoleService.DeleteadminRolePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Role.
   *
   * Returns Role data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteadminRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteadminRole(params: {

    /**
     * id of Role
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteadminRole$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
