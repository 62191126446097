/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminDocumentService } from './services/admin-document.service';
import { AdminGaleryService } from './services/admin-galery.service';
import { AdminGaleryDetailService } from './services/admin-galery-detail.service';
import { AdminHayatiService } from './services/admin-hayati.service';
import { AdminMasterBudidayaService } from './services/admin-master-budidaya.service';
import { AdminMasterHabitatService } from './services/admin-master-habitat.service';
import { AdminMasterHabitusService } from './services/admin-master-habitus.service';
import { AdminMasterJenisService } from './services/admin-master-jenis.service';
import { AdminMasterKeteranganService } from './services/admin-master-keterangan.service';
import { AdminMasterNilaiEkonomiService } from './services/admin-master-nilai-ekonomi.service';
import { AdminMasterStatusJumlahService } from './services/admin-master-status-jumlah.service';
import { AdminMasterStatusPerlindunganService } from './services/admin-master-status-perlindungan.service';
import { AdminMasterVarietesService } from './services/admin-master-varietes.service';
import { AdminMediaService } from './services/admin-media.service';
import { AdminRoleService } from './services/admin-role.service';
import { AdminUsersService } from './services/admin-users.service';
import { ClientBeritaService } from './services/client-berita.service';
import { ClientGaleryService } from './services/client-galery.service';
import { ClientGaleryDetailService } from './services/client-galery-detail.service';
import { ClientHayatiService } from './services/client-hayati.service';
import { ClientHayatiByBudidayaService } from './services/client-hayati-by-budidaya.service';
import { ClientHayatiByFamiliService } from './services/client-hayati-by-famili.service';
import { ClientHayatiByHabitatService } from './services/client-hayati-by-habitat.service';
import { ClientHayatiByTaksaService } from './services/client-hayati-by-taksa.service';
import { ClientMasterBudidayaService } from './services/client-master-budidaya.service';
import { ClientMasterHabitatService } from './services/client-master-habitat.service';
import { ClientMasterHabitusService } from './services/client-master-habitus.service';
import { ClientMasterJenisService } from './services/client-master-jenis.service';
import { ClientMasterKeteranganService } from './services/client-master-keterangan.service';
import { ClientMasterNilaiEkonomiService } from './services/client-master-nilai-ekonomi.service';
import { ClientMasterStatusJumlahService } from './services/client-master-status-jumlah.service';
import { ClientMasterStatusPerlindunganService } from './services/client-master-status-perlindungan.service';
import { ClientMasterVarietesService } from './services/client-master-varietes.service';
import { ClientMediaxService } from './services/client-mediax.service';
import { ClientPageService } from './services/client-page.service';
import { ClientPesanService } from './services/client-pesan.service';
import { ClientSlideService } from './services/client-slide.service';
import { ClientSlideImageService } from './services/client-slide-image.service';
import { CmsBeritaService } from './services/cms-berita.service';
import { CmsPageService } from './services/cms-page.service';
import { CmsPesanService } from './services/cms-pesan.service';
import { CmsSlideService } from './services/cms-slide.service';
import { CmsSlideImageService } from './services/cms-slide-image.service';
import { RawRoutePrefixCurrentUserService } from './services/raw-route-prefix-current-user.service';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { RefreshService } from './services/refresh.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminDocumentService,
    AdminGaleryService,
    AdminGaleryDetailService,
    AdminHayatiService,
    AdminMasterBudidayaService,
    AdminMasterHabitatService,
    AdminMasterHabitusService,
    AdminMasterJenisService,
    AdminMasterKeteranganService,
    AdminMasterNilaiEkonomiService,
    AdminMasterStatusJumlahService,
    AdminMasterStatusPerlindunganService,
    AdminMasterVarietesService,
    AdminMediaService,
    AdminRoleService,
    AdminUsersService,
    ClientBeritaService,
    ClientGaleryService,
    ClientGaleryDetailService,
    ClientHayatiService,
    ClientHayatiByBudidayaService,
    ClientHayatiByFamiliService,
    ClientHayatiByHabitatService,
    ClientHayatiByTaksaService,
    ClientMasterBudidayaService,
    ClientMasterHabitatService,
    ClientMasterHabitusService,
    ClientMasterJenisService,
    ClientMasterKeteranganService,
    ClientMasterNilaiEkonomiService,
    ClientMasterStatusJumlahService,
    ClientMasterStatusPerlindunganService,
    ClientMasterVarietesService,
    ClientMediaxService,
    ClientPageService,
    ClientPesanService,
    ClientSlideService,
    ClientSlideImageService,
    CmsBeritaService,
    CmsPageService,
    CmsPesanService,
    CmsSlideService,
    CmsSlideImageService,
    RawRoutePrefixCurrentUserService,
    LoginService,
    LogoutService,
    RefreshService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
