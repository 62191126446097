<app-navbar></app-navbar>

<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h1>
                    Blog Details
                </h1>
            </div>
            <div class="col-lg-4 col-md-12">
                <ul class="ps-0 mb-0 list-unstyled">
                    <li class="d-inline-block position-relative">
                        <a routerLink="/">
                            Home
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        <a routerLink="/blog">
                            Blog
                        </a>
                    </li>
                    <li class="d-inline-block position-relative">
                        Blog Details
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Blog Area -->
<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="post-details-desc">
                    <div class="image">
                        <img src="assets/images/blogs/blog1.jpg" alt="blog-image">
                    </div>
                    <ul class="meta ps-0 list-unstyled">
                        <li class="d-inline-block">
                            By:
                            <a routerLink="/">
                                Jove
                            </a>
                        </li>
                        <li class="d-inline-block">
                            25 Oct, 2023
                        </li>
                    </ul>
                    <h3>
                        Define both short-term and long-term career objectives
                    </h3>
                    <p>
                        Enim urna pulvinar orci leo viverra. A augue nisl convallis amet pellentesque sollicitudin. Commodo volutpat euismod nec duis iaculis mattis quam diam. Sollicitudin eu mi aliquet at tellus sed pellentesque. Id non in pharetra massa urna ullamcorper tempor.
                    </p>
                    <p>
                        Nulla venenatis scelerisque eget pellentesque sagittis ullamcorper at quam. Sed penatibus augue erat molestie diam vel. Ut mus quam libero eget tristique pellentesque eget vivamus mauris. Bibendum justo viverra vulputate ut. Consectetur tortor vitae diam dictum morbi morbi pulvinar eu mattis. Volutpat mauris rhoncus porttitor mattis vulputate consequat dignissim. Tristique dui orci lacus sem montes risus. Mauris in risus purus a faucibus quis enim morbi. Adipiscing eleifend non fringilla enim mi. Est integer faucibus tortor quis magna. Enim commodo turpis enim scelerisque interdum ut cursus. Eget ullamcorper vitae blandit sit.
                    </p>
                    <h3>
                        Understanding short-term career objectives
                    </h3>
                    <p>
                        Sed lobortis tincidunt cras morbi vulputate auctor a tortor faucibus. Gravida hendrerit cras ornare nulla. Fames at pharetra lacus adipiscing egestas. Consectetur blandit in sapien leo. Maecenas dui odio nibh lacinia vitae.
                    </p>
                    <ul>
                        <li>
                            Produce outline designs
                        </li>
                        <li>
                            Test and evaluate theoretical designs
                        </li>
                        <li>
                            Estimate budget and scope of project
                        </li>
                        <li>
                            Solicit observations from operators
                        </li>
                        <li>
                            Prepare product reports and documentation
                        </li>
                        <li>
                            Engage in lifelong learning and develop new theories or methods
                        </li>
                    </ul>
                    <h3>
                        Crafting long-term career objectives
                    </h3>
                    <p>
                        Nulla venenatis scelerisque eget pellentesque sagittis ullamcorper at quam. Sed penatibus augue erat molestie diam vel. Ut mus quam libero eget tristique pellentesque eget vivamus mauris. Bibendum justo viverra vulputate ut. Consectetur tortor vitae diam dictum morbi morbi pulvinar eu mattis. Volutpat mauris rhoncus porttitor mattis vulputate consequat dignissim.
                    </p>
                    <blockquote class="wp-block-quote">
                        <p>
                            Tristique dui orci lacus sem montes risus. Mauris in risus purus a faucibus quis enim morbi. Adipiscing eleifend non fringilla enim mi. Est integer faucibus tortor quis magna. Enim commodo turpis enim scelerisque interdum ut cursus.
                        </p>
                        <cite>Jason Smith</cite>
                    </blockquote>
                    <h3>
                        The balancing act
                    </h3>
                    <ul>
                        <li>
                            Alignment
                        </li>
                        <li>
                            Flexibility
                        </li>
                    </ul>
                    <h3>
                        Conclusion
                    </h3>
                    <p>
                        Sed penatibus augue erat molestie diam vel. Ut mus quam libero eget tristique pellentesque eget vivamus mauris. Bibendum justo viverra vulputate ut. Consectetur tortor vitae diam dictum morbi morbi pulvinar eu mattis. Volutpat mauris rhoncus porttitor mattis vulputate consequat dignissim. Tristique dui orci lacus sem montes risus. Mauris in risus purus a faucibus quis enim morbi. Adipiscing eleifend non fringilla enim mi. Est integer faucibus tortor quis magna. Enim commodo turpis enim scelerisque interdum ut cursus. Eget ullamcorper vitae blandit sit.
                    </p>
                </div>
                <div class="post-footer d-flex align-items-center flex-wrap">
                    <div class="post-tags">
                        <span class="sub fw-semibold d-inline-block">
                            Tags:
                        </span>
                        <ul class="mb-0 mt-0 ps-0 list-unstyled d-inline-block">
                            <li class="d-inline-block mb-0"><a routerLink="/blog" rel="tag">
                                Business
                            </a></li>
                            <li class="d-inline-block mb-0"><a routerLink="/blog" rel="tag">
                                Consulting
                            </a></li>
                        </ul>
                    </div>
                    <div class="article-share text-sm-end">
                        <ul class="social ps-0 mb-0 mt-0 list-unstyled">
                            <li class="d-inline-block mb-0"><span class="fw-semibold d-inline-block">
                                Share:
                            </span></li>
                            <li class="d-inline-block mb-0"><a href="#" class="facebook d-block rounded-circle text-center" target="_blank">
                                <i class="ri-facebook-fill"></i>
                            </a></li>
                            <li class="d-inline-block mb-0"><a href="#" class="twitter d-block rounded-circle text-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a></li>
                            <li class="d-inline-block mb-0"><a href="#" class="linkedin d-block rounded-circle text-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a></li>
                            <li class="d-inline-block mb-0"><a href="#" class="instagram d-block rounded-circle text-center" target="_blank">
                                <i class="ri-instagram-line"></i>
                            </a></li>
                        </ul>
                    </div>
                </div>
                <div class="comment-respond">
                    <h3 class="comment-reply-title">
                        Leave A Reply
                    </h3>
                    <p class="comment-notes">
                        <span id="email-notes">Your email address will not be published.</span> Required fields are marked <span class="required">*</span>
                    </p>
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>
                                        Comment *
                                    </label>
                                    <textarea class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Name *
                                    </label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>
                                        Email *
                                    </label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>
                                        Website
                                    </label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="saveMe">
                            <label class="form-check-label" for="saveMe">
                                Save my name, email, and website in this browser for the next time I comment
                            </label>
                        </div>
                        <button class="default-btn" type="submit">
                            Post A Comment
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
        </div>
    </div>
</div>

<app-subscribe></app-subscribe>

<app-footer></app-footer>

<ngx-scrolltop></ngx-scrolltop>