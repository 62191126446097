import { Component } from '@angular/core';

@Component({
  selector: 'app-leading-company',
  templateUrl: './leading-company.component.html',
  styleUrls: ['./leading-company.component.scss']
})
export class LeadingCompanyComponent {

}
