/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminMasterNilaiEkonomi } from '../models/admin-master-nilai-ekonomi';

@Injectable({
  providedIn: 'root',
})
export class AdminMasterNilaiEkonomiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getmasterNilaiEkonomisList
   */
  static readonly GetmasterNilaiEkonomisListPath = '/admin/masterNilaiEkonomis';

  /**
   * Get list of masterNilaiEkonomis.
   *
   * Returns list of masterNilaiEkonomis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getmasterNilaiEkonomisList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterNilaiEkonomisList$Response(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterNilaiEkonomi>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterNilaiEkonomiService.GetmasterNilaiEkonomisListPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {"explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * list data
         */
        'data'?: Array<AdminMasterNilaiEkonomi>;
        }>;
      })
    );
  }

  /**
   * Get list of masterNilaiEkonomis.
   *
   * Returns list of masterNilaiEkonomis
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getmasterNilaiEkonomisList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getmasterNilaiEkonomisList(params?: {

    /**
     * Tags to filter by
     */
    filter?: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterNilaiEkonomi>;
}> {

    return this.getmasterNilaiEkonomisList$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterNilaiEkonomi>;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * list data
 */
'data'?: Array<AdminMasterNilaiEkonomi>;
})
    );
  }

  /**
   * Path part for operation storeMasterNilaiEkonomi
   */
  static readonly StoreMasterNilaiEkonomiPath = '/admin/masterNilaiEkonomis';

  /**
   * Store new Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeMasterNilaiEkonomi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterNilaiEkonomi$Response(params: {
    body: AdminMasterNilaiEkonomi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterNilaiEkonomiService.StoreMasterNilaiEkonomiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterNilaiEkonomi;
        }>;
      })
    );
  }

  /**
   * Store new Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `storeMasterNilaiEkonomi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeMasterNilaiEkonomi(params: {
    body: AdminMasterNilaiEkonomi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}> {

    return this.storeMasterNilaiEkonomi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
})
    );
  }

  /**
   * Path part for operation getMasterNilaiEkonomiId
   */
  static readonly GetMasterNilaiEkonomiIdPath = '/admin/masterNilaiEkonomis/{id}';

  /**
   * Get  Master_nilai_ekonomi by id.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMasterNilaiEkonomiId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterNilaiEkonomiId$Response(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterNilaiEkonomiService.GetMasterNilaiEkonomiIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterNilaiEkonomi;
        }>;
      })
    );
  }

  /**
   * Get  Master_nilai_ekonomi by id.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMasterNilaiEkonomiId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMasterNilaiEkonomiId(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}> {

    return this.getMasterNilaiEkonomiId$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
})
    );
  }

  /**
   * Path part for operation putMasterNilaiEkonomi
   */
  static readonly PutMasterNilaiEkonomiPath = '/admin/masterNilaiEkonomis/{id}';

  /**
   * Update Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMasterNilaiEkonomi()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterNilaiEkonomi$Response(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
    body: AdminMasterNilaiEkonomi
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterNilaiEkonomiService.PutMasterNilaiEkonomiPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        'data'?: AdminMasterNilaiEkonomi;
        }>;
      })
    );
  }

  /**
   * Update Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putMasterNilaiEkonomi$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putMasterNilaiEkonomi(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
    body: AdminMasterNilaiEkonomi
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}> {

    return this.putMasterNilaiEkonomi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;
'data'?: AdminMasterNilaiEkonomi;
})
    );
  }

  /**
   * Path part for operation deleteMasterNilaiEkonomi
   */
  static readonly DeleteMasterNilaiEkonomiPath = '/admin/masterNilaiEkonomis/{id}';

  /**
   * Store new Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMasterNilaiEkonomi()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterNilaiEkonomi$Response(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
  }): Observable<StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AdminMasterNilaiEkonomiService.DeleteMasterNilaiEkonomiPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * pesan dari server
         */
        'message'?: string;
        
        /**
         * status dari server
         */
        'success'?: boolean;
        
        /**
         * data
         */
        'data'?: String;
        }>;
      })
    );
  }

  /**
   * Store new Master_nilai_ekonomi.
   *
   * Returns Master_nilai_ekonomi data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMasterNilaiEkonomi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMasterNilaiEkonomi(params: {

    /**
     * id of Master_nilai_ekonomi
     */
    id: string;
  }): Observable<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}> {

    return this.deleteMasterNilaiEkonomi$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
}>) => r.body as {

/**
 * pesan dari server
 */
'message'?: string;

/**
 * status dari server
 */
'success'?: boolean;

/**
 * data
 */
'data'?: String;
})
    );
  }

}
