<div class="blog-area pt-100 pb-75">
    <div class="container">
        <div class="section-title text-center mx-auto">
            <h2 class="mb-0">
                Simple pricing packages for all
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" data-aos="fade-in" data-aos-duration="500">
                <div class="single-pricing-box">
                    <h3 class="title">
                        Professional
                    </h3>
                    <div class="price fw-bold">
                        $99<span class="fw-normal">/month</span>
                    </div>
                    <ul class="features-list ps-0 mb-0 list-unstyled">
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Unlimited access to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            1 job per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            50 resume views per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Resume match notifications
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Sort notes to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Enhanced candidate profiles
                        </li>
                    </ul>
                    <a href="javascript:void(0);" class="d-block text-center w-100 default-btn">
                        Choose Plan
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="100">
                <div class="single-pricing-box">
                    <h3 class="title">
                        Standard
                    </h3>
                    <div class="price fw-bold">
                        $199<span class="fw-normal">/month</span>
                    </div>
                    <ul class="features-list ps-0 mb-0 list-unstyled">
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Unlimited access to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            5 jobs per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            100 resumes views per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Resume match notifications
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Sort notes to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Enhanced candidate profiles
                        </li>
                    </ul>
                    <a href="javascript:void(0);" class="d-block text-center w-100 default-btn">
                        Choose Plan
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" data-aos="fade-in" data-aos-duration="500" data-aos-delay="200">
                <div class="single-pricing-box">
                    <h3 class="title">
                        Unlimited
                    </h3>
                    <div class="price fw-bold">
                        $299<span class="fw-normal">/month</span>
                    </div>
                    <ul class="features-list ps-0 mb-0 list-unstyled">
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Unlimited access to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            10 jobs per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            500 resumes views per month
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Resume match notifications
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Sort notes to candidates
                        </li>
                        <li class="position-relative">
                            <i class="ri-check-line"></i>
                            Enhanced candidate profiles
                        </li>
                    </ul>
                    <a href="javascript:void(0);" class="d-block text-center w-100 default-btn">
                        Choose Plan
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>